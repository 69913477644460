import { useEarnnestAnalytics } from "@earnnest-e2-frontend/platform-api/src/providers/EarnnestAnalytics"
import { useEarnnestAuth0 } from "@earnnest-e2-frontend/platform-api/src/providers/EarnnestAuth0"
import Button from "@earnnest-e2-frontend/platform-ui/src/Button/Button"
import { Box, Text } from "@earnnest-e2-frontend/platform-ui/src/UI"
import React, { useEffect } from "react"

interface ErrorPageProps {
  errorCode?: string | null
  errorMessage?: string | null
}

export default function ErrorPage({ errorCode, errorMessage }: ErrorPageProps) {
  const { isAuthenticated, logout } = useEarnnestAuth0()
  const { track } = useEarnnestAnalytics()

  useEffect(() => {
    if (errorCode) {
      track(`${errorCode} Error Viewed`)
    } else {
      track("Error Viewed")
    }
  }, [track, errorCode])

  return (
    <Box
      bg="contrast0"
      style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
      <Text type="heading3" color="contrast100">
        Error {errorCode}
      </Text>
      <Box h={12} />
      <Text type="baseText" color="contrast100">
        {errorMessage || "An unexpected error occurred."}
      </Text>
      <Box h={16} />
      {isAuthenticated ? (
        <Button
          title="Logout"
          onPress={() => {
            track("Logout Attempted")
            localStorage.removeItem("loginReturnTo")
            logout({
              returnTo: `${
                window.location.origin
              }/logout?redirectTo=${encodeURIComponent(
                window.location.pathname,
              )}`,
            })
          }}
        />
      ) : null}
      <Box h={24} />
    </Box>
  )
}
