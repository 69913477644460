import {
  Context,
  FormData,
  PaymentOccasionStatus,
  TransactionStatus,
  TransactionTemplateFragment,
  TransactionTemplateStatus,
  useCalculateFeeQuery,
  useOrganizationQuery,
  usePaymentOccasionQuery,
  useTemplateAccessedAtMutation,
  useTransactionTemplateQuery,
  useUserQuery,
} from "@earnnest-e2-frontend/platform-api/src/graphql"
import { usePersistedState } from "@earnnest-e2-frontend/platform-api/src/hooks"
import { useEarnnestAnalytics } from "@earnnest-e2-frontend/platform-api/src/providers/EarnnestAnalytics"
import LoadingOverlay from "@earnnest-e2-frontend/platform-ui/src/LoadingOverlay"
import Panel from "@earnnest-e2-frontend/platform-ui/src/Panel"
import { useToast } from "@earnnest-e2-frontend/platform-ui/src/Toast"
import { ThemeProvider } from "@earnnest-e2-frontend/platform-ui/src/UI"
import moment from "moment"
import path from "path"
import { useEffect, useMemo, useRef, useState } from "react"
import { Animated } from "react-native"
import { useMediaQuery } from "react-responsive"
import {
  Redirect,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom"
import AddFundingSourceForm from "../forms/AddFundingSourceForm"
import CompletePaymentForm from "../forms/CompletePaymentForm"
import FirstTimeForm from "../forms/FirstTimeForm"
import ManageFundingSourcesForm, {
  PaymentSelection,
} from "../forms/ManageFundingSourcesForm"
import PaymentFieldsForm from "../forms/PaymentFieldsForm"
import PromotionOverlayForm from "../forms/PromotionOverlayForm"
import VerifyIdentityForm from "../forms/VerifyIdentityForm"
import ErrorPage from "./ErrorPage"

export default function PaymentPage() {
  const { triggerToast } = useToast()
  const { track, identify } = useEarnnestAnalytics()

  const [promotionModalOpen, setPromotionModalOpen] = useState(false)

  const isMobile = useMediaQuery({ maxWidth: 1000 })
  const location = useLocation()
  const history = useHistory()

  const urlMatches = {
    // general routes
    rootPayment: useRouteMatch("/:orgSlug/:method/:id"),
    editPayment: useRouteMatch("/:orgSlug/:method/:id/edit"),
    verifyIdentity: useRouteMatch("/:orgSlug/:method/:id/verify"),
    addFunding: useRouteMatch("/:orgSlug/:method/:id/funding/add/:tab?"),
    manageFunding: useRouteMatch("/:orgSlug/:method/:id/funding"),
    // flow-specific routes
    completeRequest: useRouteMatch("/:orgSlug/complete/:transactionTemplateId"),
    receivePayment: useRouteMatch("/:orgSlug/receive/:transactionTemplateId"),
    sendPayment: useRouteMatch("/:orgSlug/send/:paymentOccasionId"),
    sentPayment: useRouteMatch(
      "/:orgSlug/send/:paymentOccasionId/payment/:transactionTemplateId",
    ),
  }

  // Util to help routing and persist query params across route changes
  function relativeUrl(pathname) {
    return (
      path.join(urlMatches.rootPayment.url, pathname) + history.location.search
    )
  }

  // QUERIES

  const userQuery = useUserQuery()
  const user = userQuery.data?.user

  const organizationSlug: string = urlMatches.rootPayment.params.orgSlug
  const organizationQuery = useOrganizationQuery({
    variables: {
      slug: organizationSlug,
    },
  })

  const transactionTemplateId: string =
    urlMatches.sentPayment?.params.transactionTemplateId ||
    urlMatches.receivePayment?.params.transactionTemplateId ||
    urlMatches.completeRequest?.params.transactionTemplateId
  const transactionTemplateQuery = useTransactionTemplateQuery({
    variables: {
      id: transactionTemplateId,
    },
    skip: !transactionTemplateId,
  })
  const transactionTemplate = transactionTemplateQuery.data?.transactionTemplate

  const shouldPollForUpdates =
    transactionTemplate?.status === "TRANSACTION_PENDING" &&
    !transactionTemplate?.transactions?.length
  useEffect(() => {
    let interval
    if (shouldPollForUpdates) {
      interval = setInterval(() => {
        transactionTemplateQuery.refetch()
      }, 3000)
    } else {
      clearInterval(interval)
    }
    return () => clearInterval(interval)
  }, [transactionTemplateQuery, shouldPollForUpdates])

  const paymentOccasionId: string = transactionTemplate ? transactionTemplate.paymentOccasion.id : urlMatches.sendPayment?.params.paymentOccasionId // prettier-ignore
  const paymentOccasionQuery = usePaymentOccasionQuery({
    variables: {
      id: paymentOccasionId,
    },
    skip: !paymentOccasionId,
  })
  const paymentOccasion = paymentOccasionQuery.data?.paymentOccasion

  const [accessTemplate] = useTemplateAccessedAtMutation()

  // All the derived state
  const loading =
    userQuery.loading ||
    organizationQuery.loading ||
    paymentOccasionQuery.loading ||
    transactionTemplateQuery.loading

  const error =
    userQuery.error ||
    organizationQuery.error ||
    paymentOccasionQuery.error ||
    transactionTemplateQuery.error

  const readOnly = urlMatches.sendPayment
    ? // we're sending a payment but the transaction template already exists so payment has been authorized
      !!transactionTemplate
    : // we're receiving a payment but the transaction already exists so payment has been initiated
      !!transactionTemplate?.transactions?.length

  useEffect(() => {
    if (readOnly) {
      track("Completed Payment Viewed")
    }
  }, [readOnly, track])

  useEffect(() => {
    if (user) {
      identify(user.id, {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        providerStatus: user.customer?.status || "UNVERIFIED",
      })
    }
  }, [user, identify])

  // Util to help save state to local storage only for the current payment occasion
  function localStorageKey(key) {
    return `${urlMatches.rootPayment?.params.id}:${key}`
  }

  const [seen, setSeen] = usePersistedState<boolean>(
    localStorageKey("seen"),
    false,
  )

  const [
    lastCreatedTemplate,
    setLastCreatedTemplate,
  ] = usePersistedState<TransactionTemplateFragment | null>(
    localStorageKey("lastCreatedTemplate"),
    null,
  )

  const [
    paymentSelection,
    setPaymentSelection,
  ] = usePersistedState<PaymentSelection | null>(
    localStorageKey("paymentSelection"),
    null,
  )

  // Save payment form data to local storage if user has filled out any form fields
  const [paymentFormData, setPaymentFormData] = usePersistedState<FormData[]>(
    localStorageKey("form"),
    [],
  )

  // If form data is encoded in URL, update payment form data
  const urlSearchParams = new URLSearchParams(location.search)
  const urlDataParam = urlSearchParams.get("data")
  const urlFormData: FormData[] = useMemo(() => {
    if (urlDataParam) {
      try {
        const urlDataObj = JSON.parse(window.atob(urlDataParam))
        return Object.keys(urlDataObj).map((key) => {
          return { name: key, value: urlDataObj[key] }
        })
      } catch (error) {
        console.error(error)
      }
    }
    return []
  }, [urlDataParam])

  // Update form data anytime encoded URL data changes
  useEffect(() => {
    if (urlFormData.length) {
      setPaymentFormData((formData) => {
        // If url form data exists, we want to use only the values NOT set in url form data
        const userFormData = formData.filter((x) => {
          return urlFormData.find((y) => x.name !== y.name)
        })
        return [...urlFormData, ...userFormData]
      })
    }
  }, [urlFormData, setPaymentFormData])

  // If user has entered an amount and the payment occasion includes a fee, calculate the fee:
  const paymentAmount =
    paymentFormData.find((x) => x.name === "amount")?.value ||
    transactionTemplate?.amount
  const calculateFeeQuery = useCalculateFeeQuery({
    skip: !paymentAmount || !paymentOccasion?.calculateFee || !paymentSelection,
    variables: {
      // NOTE: must use the real ID of the payment occasion, not the slug ID
      transactionTemplateId: transactionTemplate?.id,
      paymentOccasionId: paymentOccasion?.id,
      paymentType: paymentSelection?.type,
      amount: Number(paymentAmount),
    },
  })
  const paymentFees = calculateFeeQuery.data?.calculateFee

  const isPanelOpen =
    urlMatches.editPayment ||
    urlMatches.verifyIdentity ||
    urlMatches.manageFunding ||
    (!seen && !readOnly)

  // Push payment summary card to the right when panel is open
  const contentOffset = useRef(new Animated.Value(isPanelOpen ? 300 : 0))
  useEffect(() => {
    Animated.timing(contentOffset.current, {
      toValue: isPanelOpen ? 300 : 0,
      duration: 200,
      useNativeDriver: true,
    }).start()
  }, [isPanelOpen])

  if (process.env.NODE_ENV === "development") {
    console.log({
      urlMatches,
      user,
      transactionTemplate,
      paymentOccasion,
      seen,
      lastCreatedTemplate,
      paymentSelection,
      paymentFormData,
      urlFormData,
      paymentFees,
    })
  }

  if (loading) {
    return <LoadingOverlay />
  }

  if (
    error ||
    (urlMatches.receivePayment && !transactionTemplate) ||
    (urlMatches.completeRequest && !transactionTemplate) ||
    (urlMatches.sendPayment && !paymentOccasion)
  ) {
    return (
      <ErrorPage
        errorMessage={error?.message || "Could not access payment details."}
      />
    )
  }

  // handle invalid routes
  if (
    // receive route but not a B2C payment
    (urlMatches.receivePayment && paymentOccasion.direction !== "OUTBOUND") ||
    // send route but not a C2B payment
    (urlMatches.sendPayment && paymentOccasion.direction !== "INBOUND") ||
    // complete route but not a C2B payment
    (urlMatches.completeRequest && paymentOccasion.direction !== "INBOUND") ||
    // valid route but payment occasion doesn't match organization slug
    paymentOccasion?.organization?.slug !== organizationSlug
  ) {
    return (
      <ErrorPage
        errorCode="404"
        errorMessage="This is not a valid payment url."
      />
    )
  }

  if (paymentOccasion?.status === PaymentOccasionStatus.Inactive) {
    return <ErrorPage errorMessage={`This payment is no longer available.`} />
  }

  if (
    paymentOccasion?.context === Context.Emd &&
    transactionTemplate?.receiverEmail &&
    transactionTemplate?.receiverEmail?.toLowerCase() !==
      user?.email?.toLowerCase()
  ) {
    return (
      <ErrorPage
        errorMessage={`This payment is not available for ${user.email}. Please login with the email that received this request.`}
      />
    )
  }

  if (transactionTemplate?.status === "CANCELED") {
    return (
      <ErrorPage errorMessage="This payment was canceled and is no longer available." />
    )
  }

  if (
    urlMatches.sendPayment &&
    !urlMatches.sentPayment &&
    lastCreatedTemplate &&
    lastCreatedTemplate.id &&
    lastCreatedTemplate.createdAt &&
    moment().diff(lastCreatedTemplate.createdAt, "hours") < 24
  ) {
    return <Redirect to={relativeUrl(`/payment/${lastCreatedTemplate.id}`)} />
  }

  if (
    paymentOccasion &&
    !transactionTemplate &&
    !paymentFormData.length &&
    !urlMatches.editPayment
  ) {
    // Force valid payment configuration first
    return <Redirect to={relativeUrl("/edit")} />
  }

  return (
    <>
      <Animated.View
        style={{
          flex: 1,
          transform: [{ translateX: isMobile ? 0 : contentOffset.current }],
        }}>
        <CompletePaymentForm
          key={transactionTemplateId}
          organizationSlug={organizationSlug}
          paymentOccasionId={paymentOccasionId}
          transactionTemplateId={transactionTemplateId}
          paymentSelection={paymentSelection}
          paymentFormData={paymentFormData}
          paymentFees={paymentFees}
          lastCreatedTemplate={lastCreatedTemplate}
          onVerifyIdentity={() => history.push(relativeUrl("/verify"))}
          onEditPayment={() => history.push(relativeUrl("/edit"))}
          onSelectFundingSource={() => {
            // @ts-ignore
            if (user?.fundingSources.length || user?.paymentMethods.length) {
              history.push(relativeUrl("/funding"))
            } else {
              history.push(relativeUrl("/funding/add"))
            }
          }}
          onTemplateCreated={async (transactionTemplate) => {
            // When user creates a transaction template from a transaction type (via sendTransaction)
            // redirect them to the finished payment route so they have a reference url.
            if (transactionTemplate && urlMatches.sendPayment) {
              setLastCreatedTemplate(transactionTemplate)
              // Perform the query before changing route so no loading screen is necessary
              const result = await transactionTemplateQuery.refetch({
                id: transactionTemplate.id,
              })
              if (
                result.data?.transactionTemplate?.status ===
                  TransactionTemplateStatus.ApprovalPending ||
                result.data?.transactionTemplate?.transactions?.[0]?.status ===
                  TransactionStatus.ApprovalPending
              ) {
                triggerToast({
                  kind: "info",
                  message:
                    "The transaction will be initiated once the receiver accepts the payment.",
                })
              }
              history.push(relativeUrl(`/payment/${transactionTemplate.id}`))
            }
          }}
          onClearLastCreatedTemplate={() => {
            setLastCreatedTemplate(null)
            history.replace(relativeUrl("/"))
          }}
          onSubmitSuccess={async (transactionTemplate) => {
            setPaymentSelection(null)
            setPaymentFormData([])
            setPromotionModalOpen(true)
          }}
        />
      </Animated.View>
      <ThemeProvider activeScheme="light">
        <Panel
          side="left"
          overlay={true}
          onBack={
            urlMatches.verifyIdentity && user?.customer?.status !== "VERIFIED"
              ? // User is verifying identity and can go back to bank or card selection
                () => history.push(relativeUrl("/funding/add"))
              : urlMatches.addFunding &&
                (user?.fundingSources?.length || user?.paymentMethods?.length)
              ? // User is adding a funding source and can go back to manage funding sources
                () => history.push(relativeUrl("/funding"))
              : undefined
          }
          onClose={() => history.push(relativeUrl("/"))}>
          {readOnly ? null : !seen ? (
            <FirstTimeForm
              organizationSlug={organizationSlug}
              paymentOccasionId={paymentOccasionId}
              onSubmit={async () => {
                setSeen(true)
                if (
                  transactionTemplateId &&
                  paymentOccasion?.context === Context.Emd
                ) {
                  try {
                    await accessTemplate({
                      variables: {
                        transactionTemplateId,
                      },
                    })
                  } catch (error) {
                    triggerToast({
                      kind: "error",
                      message: error.message,
                    })
                  }
                }
              }}
            />
          ) : urlMatches.editPayment ? (
            <PaymentFieldsForm
              urlFormData={urlFormData}
              paymentFormData={paymentFormData}
              paymentOccasionId={paymentOccasionId}
              onSubmit={(values) => {
                let formData: FormData[] = []
                Object.keys(values).forEach((key) => {
                  formData.push({ name: key, value: values[key] })
                })
                setPaymentFormData(formData)
                history.push(relativeUrl("/"))
              }}
            />
          ) : urlMatches.verifyIdentity ? (
            <VerifyIdentityForm
              onVerified={() => {
                history.push(relativeUrl("/funding/add"))
              }}
            />
          ) : urlMatches.addFunding ? (
            <AddFundingSourceForm
              acceptsCreditCards={paymentOccasion.acceptsCreditCards}
              activeTab={urlMatches.addFunding.params.tab || "bank"}
              onTabChange={(tab) => {
                history.push(relativeUrl(`/funding/add/${tab}`))
              }}
              onVerifyIdentity={() => {
                history.push(relativeUrl(`/verify`))
              }}
              onNewPaymentMethod={(paymentMethod) => {
                track("Funding Source Changed")
                setPaymentSelection({
                  type: "card",
                  id: paymentMethod.id,
                })
                history.push(relativeUrl(`/funding`))
              }}
              onNewFundingSource={(fundingSource) => {
                track("Funding Source Changed")
                setPaymentSelection({
                  type: "bank_account",
                  id: fundingSource.id,
                })
                history.push(relativeUrl(`/funding`))
              }}
              getPlaidContinueUrl={() => relativeUrl("/funding")}
            />
          ) : urlMatches.manageFunding ? (
            <ManageFundingSourcesForm
              acceptsCreditCards={paymentOccasion.acceptsCreditCards}
              initialPaymentSelection={paymentSelection}
              onAddFundingSource={() =>
                history.push(relativeUrl("/funding/add"))
              }
              onPaymentSelection={(paymentSelection) => {
                track("Funding Source Changed")
                history.push(relativeUrl("/"))
                setPaymentSelection(paymentSelection)
              }}
            />
          ) : null}
        </Panel>
      </ThemeProvider>
      {promotionModalOpen ||
      (process.env.NODE_ENV === "development" &&
        location.search?.includes("debug_promo=true")) ? (
        <PromotionOverlayForm transactionTemplate={transactionTemplate} />
      ) : null}
    </>
  )
}
