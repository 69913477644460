import {
  Context,
  TransactionTemplateFragment,
} from "@earnnest-e2-frontend/platform-api/src/graphql"
import {
  ActionIcon,
  Button,
  Checkbox,
  Container,
  Group,
  Image,
  Overlay,
  Paper,
  ScrollArea,
  Space,
  Text,
  Title,
} from "@mantine/core"
import { useLocalStorage } from "@mantine/hooks"
import moment from "moment"
import { useState } from "react"
import { RiCloseLine } from "react-icons/ri"
import ParseAddress from "parse-address"

const BLOCKLIST = [
  "28", // Title Forward - National
  "29", // Title Forward - Virginia
  "31", // Title Forward - Texas
  "34", // Title Forward - Florida
  "50", // Title Forward - Maryland
  "51", // Title Forward - Colorado
  "70", // Holliday Ingram
  "112", // PalmerHouse Properties (PHPA-TN)
  "126", // FC Tucker Company, Inc.
  "757", // Keefe Real Estate, Inc.
  "764", // Howard Hanna Real Estate Services
  "848", // GVRME
  "966", // Urban Compass Inc. dba Compass Tennessee LLC
  "1037", // Howard Hanna The Frederick Group
  "1051", // KW Success
  "1056", // KW St. George
  "1068", // Compass, Inc. CONSUMER ACCOUNT
  "1087", // Great American Title
  "1165", // Title Forward - Washington
  "1182", // Berkshire Hathaway HomeServices Indiana Realty
  "1366", // Howard Hanna Simon Real Estate Services*
  "1410", // Berkshire Hathaway HomeServices
  "1568", // Rockhaven Homes, LLC
  "1570", // Baldwin Home Builders, LLC
  "1571", // Cornerstone Fulton Home Builders, Inc.
  "1572", // High Grove Home Builders, Inc.
  "1573", // Keswick Home Builders, Inc.
  "1575", // Jonesboro Home Builders, Inc.
  "1576", // Trinity Knight, LLC
  "1577", // Stonecrest Home Builders, Inc.
  "1578", // Parks at Browns Mill Home Builders, Inc.
  "1579", // Jefferson Downs Home Builders, Inc.
  "1661", // RH Lot Home Builders, Inc.
  "1785", // Howard Hanna
  "1856", // Bolst, Inc.
  "1909", // Pocono Regional Realty
  "1929", // Absolute Title Agency, LLC
  "2154", // F. C. Tucker/Thompson
  "2232", // Paradiem LLC DBA FC Tucker Realty Ctr
  "2245", // Meybohm Commercial Properties, LLC
  "2340", // CLOSED
  "2371", // Tucker Mortgage
  "2383", // Alliance Nationwide
  "2411", // Compass Texas
  "2418", // Bucks County Regional Realty
  "2499", // Autumn Brook Holdings, LLC
  "2501", // Ashbrooke Home Builders, Inc.
  "2510", // Paul McInnis LLC
  "2610", // Kennon, Parker, Duncan & Davis LLC (Coldwell Banker KPDD)
  "2629", // Howard Hanna Premier Properties By Barbara Alexander, LLC
  "2656", // Hoschton Towns, LLC
  "2850", // Cornerstone Fairburn Holdings, LLC
  "2863", // View Homes Title LLC
  "2985", // Title Forward - California Inc.
  "3000", // Howard Hanna Mortgage
  "3042", // The Agency Nashville, LLC
  "3043", // Stonewall Tell Home Builders, Inc.
  "3164", // 1st Priority Mortgage
  "3271", // Villages at East Point, LLC
  "3272", // Kensington Home Builders, Inc.
  "3273", // Riverwalk Home Builders, Inc.
  "3610", // Title Forward - Illinois
  "3618", // Title Forward - Arizona
  "3751", // Backwoods Land Company
  "3752", // TitleOne
  "3915", // Stylecraft Builders, Inc.
  "3916", // Stylecraft Falcon Pointe
  "3917", // Stylecraft Central Texas
  "3942", // American Title Company of Washtenaw
  "4066", // Rapid Fire Home Buyers
]

type Promotion = {
  id: string
  heading: string
  description: string
  prompt: string
  image: string
  disclaimer?: string
  optOut?: boolean
  emdOnly?: boolean
  signup: (transactionTemplate: TransactionTemplateFragment) => Promise<void>
}

const PROMOTIONS = {
  transactly: {
    id: "TR",
    heading: "Transactly",
    description: "",
    prompt: "Send me free recommendations for services in my area",
    image: "/transactly-banner.png",
    emdOnly: true,
    signup: async (transactionTemplate: TransactionTemplateFragment) => {
      const payload = {
        rentOrOwn: "Own",
        moveInDate: moment().add(30, "days").format("YYYY-MM-DD"),
        firstName: transactionTemplate.buyer?.firstName || "",
        lastName: transactionTemplate.buyer?.lastName || "",
        email: transactionTemplate.buyer?.email || "",
        phone: transactionTemplate.buyer?.phone || "",
        address: "",
        city: "",
        state_province: "",
        zip: "",
      }
      transactionTemplate.formData?.forEach((field) => {
        if (field.name === "address_line_1") payload.address = field.value
        if (field.name === "city") payload.city = field.value
        if (field.name === "state_or_region")
          payload.state_province = field.value
        if (field.name === "postal_code") payload.zip = field.value
      })
      await fetch(
        "https://earnnest-sf-forms.netlify.app/.netlify/functions/transactlyLead",
        {
          method: "POST",
          body: JSON.stringify(payload),
        },
      )
    },
  },
  utilityconcierge: {
    id: "UC",
    heading: "Utility Concierge",
    description: `Moving is a major hassle on your own, but with Move Concierge, it doesn't have to be. From internet and cable to utilities and home security, we'll ensure everything is ready to go when you move in. You not only save hours of research, but you also get access to the best deals saving you money. No more endless phone calls, waiting on hold or scheduling headaches—Move Concierge will handle it all for you. And best of all, it's all free because you're working with Earnnest. Happy moving!`,
    image: "/utility-concierge-banner.png",
    prompt: "Send me additional information",
    disclaimer: `By clicking, you are providing your contact information and consent for Move Concierge to contact you via telephone, email, and text message, including written consent to calls and text messages that are auto-dialed as well as calls and text messages that use artificial or prerecorded voice, from or on behalf of Move Concierge at the telephone number(s) from the company (even if they are on the Do-Not-Call Registry).`,
    emdOnly: true,
    signup: async (transactionTemplate: TransactionTemplateFragment) => {
      const url =
        process.env.REACT_APP_ENVIRONMENT === "prod"
          ? `https://hooks.zapier.com/hooks/catch/10787526/bpp6in8/`
          : `https://hooks.zapier.com/hooks/catch/10787526/bxv3pos/`
      const payload = {
        "Date/Time": moment().toISOString(),
        NAME: transactionTemplate.buyer?.fullName || "",
        EMAIL: transactionTemplate.buyer?.email || "",
        PHONE: transactionTemplate.buyer?.phone || "",
        ADDRESS: "",
        CITY: "",
        STATE: "",
        ZIP: "",
      }
      transactionTemplate.formData?.forEach((field) => {
        if (field.name === "address_line_1") payload.ADDRESS = field.value
        if (field.name === "city") payload.CITY = field.value
        if (field.name === "state_or_region") payload.STATE = field.value
        if (field.name === "postal_code") payload.ZIP = field.value
        if (field.name === "propaddress") {
          try {
            const parsedAddress = ParseAddress.parseLocation(field.value)
            payload.ADDRESS = field.value || ""
            payload.CITY = parsedAddress.city || ""
            payload.STATE = parsedAddress.state || ""
            payload.ZIP = parsedAddress.zip || ""
          } catch (error) {
            payload.ADDRESS = field.value || ""
          }
        }
      })
      await fetch(url, {
        method: "POST",
        body: JSON.stringify(payload),
      })
    },
  },
  meybohm: {
    id: "ME",
    heading: "",
    description: "",
    prompt: "Send me additional information",
    image: "/meybohm-banner.png",
    optOut: true,
    emdOnly: true,
    signup: async (transactionTemplate: TransactionTemplateFragment) => {
      const url =
        process.env.REACT_APP_ENVIRONMENT === "prod"
          ? `https://hooks.zapier.com/hooks/catch/10787526/23o1963/`
          : `https://hooks.zapier.com/hooks/catch/10787526/23o95ba/`
      const payload = {
        "Date/Time": moment().toISOString(),
        NAME: transactionTemplate.buyer?.fullName || "",
        EMAIL: transactionTemplate.buyer?.email || "",
        PHONE: transactionTemplate.buyer?.phone || "",
        ADDRESS: "",
        CITY: "",
        STATE: "",
        ZIP: "",
      }
      transactionTemplate.formData?.forEach((field) => {
        if (field.name === "address_line_1") payload.ADDRESS = field.value
        if (field.name === "city") payload.CITY = field.value
        if (field.name === "state_or_region") payload.STATE = field.value
        if (field.name === "postal_code") payload.ZIP = field.value
        if (field.name === "propaddress") {
          try {
            const parsedAddress = ParseAddress.parseLocation(field.value)
            payload.ADDRESS = field.value || ""
            payload.CITY = parsedAddress.city || ""
            payload.STATE = parsedAddress.state || ""
            payload.ZIP = parsedAddress.zip || ""
          } catch (error) {
            payload.ADDRESS = field.value || ""
          }
        }
      })
      await fetch(url, {
        method: "POST",
        body: JSON.stringify(payload),
      })
    },
  },
  carpenter: {
    id: "CM",
    heading: "",
    description: "",
    prompt: "Send me additional information",
    image: "/carpenter-banner.png",
    signup: async (transactionTemplate: TransactionTemplateFragment) => {
      const url =
        process.env.REACT_APP_ENVIRONMENT === "prod"
          ? `https://hooks.zapier.com/hooks/catch/10787526/2durv0r/`
          : `https://hooks.zapier.com/hooks/catch/10787526/2duim8w/`
      const payload = {
        "Date/Time": moment().toISOString(),
        NAME: transactionTemplate.buyer?.fullName || "",
        EMAIL: transactionTemplate.buyer?.email || "",
        PHONE: transactionTemplate.buyer?.phone || "",
        ADDRESS:
          transactionTemplate.formData?.find((x) => x.name === "propaddress")
            ?.value ||
          transactionTemplate.formData?.find(
            (x) => x.name === "property_address",
          )?.value ||
          transactionTemplate.formData?.find((x) => x.name === "address_line_1")
            ?.value ||
          "",
      }
      await fetch(url, {
        method: "POST",
        body: JSON.stringify(payload),
      })
    },
  },
}

function getPromotion(
  transactionTemplate: TransactionTemplateFragment,
): Promotion | undefined {
  let p: Promotion = PROMOTIONS.utilityconcierge
  if (
    transactionTemplate?.receivingOrganization?.id === "2156" ||
    transactionTemplate?.escrowAccount?.organization?.id === "2156" ||
    transactionTemplate?.paymentOccasion?.organization?.id === "2156"
  ) {
    p = PROMOTIONS.meybohm
  } else if (transactionTemplate?.paymentOccasion?.id === "102") {
    p = PROMOTIONS.carpenter
  } else if (
    BLOCKLIST.includes(transactionTemplate?.paymentOccasion?.organization?.id)
  ) {
    return
  }
  if (
    p.emdOnly &&
    transactionTemplate?.paymentOccasion?.context !== Context.Emd
  ) {
    return
  }
  return p
}

export default function PromotionOverlayForm({
  transactionTemplate,
}: {
  transactionTemplate: TransactionTemplateFragment
}) {
  const promotion = getPromotion(transactionTemplate)

  const [viewed, setViewed] = useLocalStorage({
    key: `promotion-viewed-${transactionTemplate?.id}`,
    defaultValue: "",
  })

  const [checked, setChecked] = useState(promotion?.optOut ? true : false)

  if (!promotion || viewed) {
    return null
  }

  return (
    <Overlay bg="dark.6">
      <ScrollArea h="100vh">
        <Container size="sm" my={100}>
          <Paper p="xl">
            <Group align="start" position="apart" noWrap>
              <Title size="h2" order={2}>
                Free, because you used Earnnest!
              </Title>
              <ActionIcon
                size="md"
                color="dark"
                variant="light"
                onClick={() => setViewed(new Date().toISOString())}>
                <RiCloseLine size={24} />
              </ActionIcon>
            </Group>
            <Text sx={{ whiteSpace: "pre-wrap" }}>{promotion.description}</Text>
            <Space h="xs" />
            <Image
              src={promotion.image}
              height="auto"
              width="100%"
              sx={(theme) => ({
                overflow: "hidden",
                borderRadius: theme.radius.md,
                border: `1px solid ${theme.fn.themeColor("gray.2")}`,
              })}
            />
            <Space h="xl" />
            <Checkbox
              size="lg"
              checked={checked}
              onChange={() => setChecked((x) => !x)}
              label={
                <>
                  <Text size="md" weight="bold">
                    {promotion.prompt}
                  </Text>
                  <Text size="sm">
                    {promotion.disclaimer ||
                      "Earnnest will only share your name, email, phone number, and address."}
                  </Text>
                </>
              }
            />
            <Space h="xl" />
            <Button
              size="lg"
              color="green"
              fullWidth={true}
              onClick={async () => {
                try {
                  setViewed(new Date().toISOString())
                  if (checked) {
                    await promotion.signup(transactionTemplate)
                  }
                } catch (error) {
                  console.error(error)
                  // swallow error, we don't want to disrupt user from seeing their payment confirmation
                }
              }}>
              Continue to payment confirmation
            </Button>
          </Paper>
        </Container>
      </ScrollArea>
    </Overlay>
  )
}
